const fullScreenAction = {
	id: 'fullScreen',
	selectionType: 'general',
	label: 'actions.stopmanoeuvre.fullscreen',
	functionality: 'READ_STOP_MANOEUVRE',
	checkAvailability: function () {
		return true;
	},
	runAction: function () {
		const elem = document.getElementById('app');
		if (elem) {
			if (elem.requestFullscreen) {
				elem.requestFullscreen();
			} else if (elem.webkitRequestFullscreen) {
				/* Safari */
				elem.webkitRequestFullscreen();
			} else if (elem.msRequestFullscreen) {
				/* IE11 */
				elem.msRequestFullscreen();
			}
		}
		this.$puiEvents.$emit('onPui-action-running-ended-stopmanoeuvre');
	}
};

export default {
	actions: [fullScreenAction]
};
