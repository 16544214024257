<template>
	<pui-datatable :modelName="modelName" :actions="actions" :modelColumnDefs="columnDefs" :externalFilter="externalFilter">
		<template slot="toolbarAdditionalBtns">
			<div>
				<label>{{ $t('grid.stopmanoeuvre.lastupdate') }}: {{ lastUpdate }}</label>
			</div>
		</template>
	</pui-datatable>
</template>

<script>
import StopManoeuvreActions from './StopManoeuvreActions.js';

export default {
	name: 'StopManoeuvreGrid',
	data() {
		return {
			modelName: 'stopmanoeuvre',
			actions: StopManoeuvreActions.actions,
			lastUpdate: this.$dateTimeUtils.getLocalTimeNow('HH:mm:ss'),
			columnDefs: {
				etaterminal: {
					render: (data, _type, row) => {
						data = this.$dateTimeUtils.formatDate(data, this.dateTimeFormat);
						if (
							row.etc &&
							this.$dateTimeUtils.isLocalDateSameOrAfter(row.etaterminal, row.etc) &&
							this.$dateTimeUtils.isLocalDateSameOrAfter(row.etaterminal, row.etdterminal)
						) {
							return `<label class="stopmanoeuvre_alert">${data}</label>`;
						}
						return data;
					}
				},
				etdterminal: {
					render: (data, _type, row) => {
						data = this.$dateTimeUtils.formatDate(data, this.dateTimeFormat);
						if (row.etc && this.$dateTimeUtils.isLocalDateSameOrBefore(row.etdterminal, row.etc)) {
							return `<label class="stopmanoeuvre_alert">${data}</label>`;
						}
						return data;
					}
				}
			}
		};
	},
	computed: {
		externalFilter() {
			let defaultQuay;
			if (this.$store.getters.isTerminalAPMT) {
				defaultQuay = this.$store.state.berthplanner.defaultQuayAPMT;
			} else if (this.$store.getters.isTerminalTTIA) {
				defaultQuay = this.$store.state.berthplanner.defaultQuayTTIA;
			} else if (this.$store.getters.isTerminalAPMTLC) {
				defaultQuay = this.$store.state.berthplanner.defaultQuayAPMTLC;
			} else if (this.$store.getters.isTerminalHPLCT) {
				defaultQuay = this.$store.state.berthplanner.defaultQuayHPLCT;
			}

			if (!defaultQuay) {
				return {
					rules: [{ field: 'port_id', op: 'in', data: window.localStorage.getItem('workingPortsId') }]
				};
			}

			let op = 'eq';
			let data = defaultQuay.id;
			if (defaultQuay.groupIds) {
				op = 'in';
				data = defaultQuay.groupIds;
			}

			return {
				rules: [{ field: 'location_id', op, data }]
			};
		},
		onPuiModelsLoadedAndSaved() {
			return this.$store.state.modelsLoadedAndSaved;
		},
		dateTimeFormat() {
			return `${this.$store.getters.dateFormat} ${this.$store.getters.timeFormat}`;
		}
	},
	watch: {
		onPuiModelsLoadedAndSaved() {
			this.modelConfig = this.$store.getters.getModelByName(this.modelName);
			if (this.modelConfig) {
				this.onReady();
			}
		}
	},
	mounted() {
		this.modelConfig = this.$store.getters.getModelByName(this.modelName);
		if (this.modelConfig) {
			this.onReady();
		}
	},
	beforeDestroy() {
		clearInterval(this.counter);
	},
	methods: {
		onReady() {
			this.checkPeriod = this.modelConfig.defaultConfiguration.refreshSeconds;
			this.startTimer(this.checkPeriod);
		},
		startTimer(countValue) {
			this.count = countValue;
			this.lastUpdate = this.$dateTimeUtils.getLocalTimeNow('HH:mm:ss');
			this.counter = setInterval(this.timer, 1000);
		},
		timer() {
			this.count = this.count - 1;
			if (this.count < 0) {
				clearInterval(this.counter);
			} else if (this.count === 0) {
				clearInterval(this.counter);
				if (this.$store.getters.getToken !== null) {
					this.startTimer(this.checkPeriod);
				}
			}
		}
	}
};
</script>

<style>
.stopmanoeuvre_alert {
	color: red;
	font-weight: bold;
}
</style>
